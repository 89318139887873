<script>
import Layout from '@layouts/auth'
import { authMethods } from '@state/helpers'
import swalFeedback from '@src/mixins/swalFeedback.vue'

export default {
    components: { Layout },
    mixins: [swalFeedback],
    data() {
        return {
            email: '',
            tryingToReset: false,
        }
    },
    computed: {
        i18nAlreadyHaveAccount() {
            return this.getI18n('COMMON', 'already_have_account')
        },
        i18nLogin() {
            return this.getI18n('COMMON', 'login')
        },
        i18nResetPassword() {
            return this.getI18n('COMMON', 'reset_password')
        },
    },
    methods: {
        ...authMethods,
        // Try to register the user in with the email, fullname
        // and password they provided.
        tryToReset() {
            this.tryingToReset = true
            return this.resetPassword({
                email: this.email,
            })
                .then((data) => {
                    this.tryingToReset = false
                    this.successFeedback(this.i18nResetPassword, data.message)
                })
                .catch((error) => {
                    this.tryingToReset = false
                    this.warningFeedback(this.i18nResetPassword, error && error.response ? error.response.data.message : error.statusText)
                })
        },
    },
}
</script>

<template>
    <Layout>
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="d-flex">
                    <div class="card-body p-4">
                        <div class="text-center w-100 m-auto">
                            <a href="/">
                                <span
                                    ><img
                                        class="img-fluid"
                                        src="@assets/images/logo-login.png"
                                        alt=""
                                        height="116"
                                /></span>
                            </a>
                        </div>
                        <b-form
                            class="form-access"
                            @submit.prevent="tryToReset"
                        >
                            <b-form-group
                                id="email-group"
                                label="E-mail"
                                label-for="email"
                            >
                                <b-form-input
                                    id="email"
                                    v-model="email"
                                    type="email"
                                    required
                                    :disabled="tryingToReset"
                                    placeholder="Email"
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group id="button-group" class="mt-4">
                                <b-button
                                    type="submit"
                                    variant="primary"
                                    class="btn-block"
                                    :disabled="tryingToReset"
                                >
                                    <template v-if="tryingToReset">
                                        <b-spinner small />
                                    </template>
                                    <template v-else>
                                        <span>
                                            {{ i18nResetPassword }}
                                        </span>
                                    </template>
                                </b-button>
                            </b-form-group>
                        </b-form>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->

                <div class="row">
                    <div class="col-12 text-center">
                        <p class="text-white-50">
                            {{ i18nAlreadyHaveAccount }}?
                            <router-link
                                v-slot="{ navigate }"
                                to="/login"
                                custom
                            >
                                <a
                                    class="text-white ml-1"
                                    style="cursor: pointer"
                                    role="link"
                                    @click="navigate"
                                    >
                                    <b>
                                        {{ i18nLogin }}
                                    </b>
                                </a>
                            </router-link>
                        </p>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </Layout>
</template>

<style lang="scss" module></style>
